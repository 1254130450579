/*
* 업무구분: 디지털프로필
* 화면 명: MSPBC100M
* 화면 설명: 내 프로필 메인
*/
<template>
  <ur-page-container class="msp msp-bc-wrap" type="subpage" :show-title="true" title="내 정보" :topButton="false">

    <!-- Content영역 -->
    <ur-box-container direction="column" alignV="start" class="msp-bc-wrap">        
      <ur-box-container alignV="start" componentid="" direction="column" class="pl24 pr24 pt30 mb50">
        <ur-box-container alignV="start" componentid="" direction="column" class="home_profile--box ty2">
          <div class="profile_box_wrap">
            <div class="fexTy3 align-item-center">
              <div class="name_wrap txt-left">
                <h2 class="fs26rem fwb mb5">{{userNm}}</h2>
                <mo-badge class="badge-sample-badge lightblue sm" text="컨설턴트" shape="status">{{userGrd}}</mo-badge> 
              </div>
              <div class="photo_wrap" @click="fn_UpdPhoto">
                <img v-bind:src="profileImg" ref="profileImgRef" alt="" class="profile_img">
                <mo-button class="photo_add">사진등록</mo-button>
              </div>
            </div>
            <ul class="info_wrap mt20">
              <li class="user fs16rem fwb">{{lv_UserVo.userEno}}</li>
              <li class="cp fs16rem fwb fexTy3">
                {{celno}}
                <mo-button class="btn_profile_edit mt5" @click="fn_updUserInfo" v-if="lv_UserVo.userEno.substring(0,1) === '0'">정보수정</mo-button>
              </li>
              <li class="mail fs16rem fwb">{{fn_GetEmailAddr(lv_UserVo)}}</li>
            </ul>
            <ur-box-container>          
              <div class="footer_wrap mt30 mb50">
                <h2>{{userDeptNm}}</h2>
                <p class="fs16rem mb10 mt10">({{lv_UserVo.hdofFrnoPstcd}}{{lv_UserVo.hdofBknoPstcd}}) {{lv_UserVo.hdofAddr}}</p>
                <div class="">
                  <span class="fs14rem mr10 crTy-bk7">지점</span>
                  <span class="fs16rem"><a :href="`tel:${$bizUtil.telNoWithHyphen(lv_UserVo.coTelno, false)}`">{{$bizUtil.telNoWithHyphen(lv_UserVo.coTelno, false)}}</a></span>
                  <em class="em_normal">ㅣ</em>
                  <span class="fs14rem mr10 crTy-bk7">FAX</span>
                  <span class="fs16rem"><a :href="`tel:${$bizUtil.telNoWithHyphen(lv_UserVo.coFaxno, false)}`">{{$bizUtil.telNoWithHyphen(lv_UserVo.coFaxno, false)}}</a></span>
                </div>
              </div>
            </ur-box-container>
            <ur-box-container class="bg_profile--lightblue">
              <div class="full">
                <div class="settion_wrap fexTy3" v-if="!isGA">              
                <mo-button class="btn_profile_pop" @click="fn_MoveMSPBC102P">디지털 프로필</mo-button>
                <mo-button class="btn_profile_set--text arrow-right" @click="fn_MoveToMSPBC101M()">설정</mo-button>
              </div>
              <p class="fs17rem mt5 mb20" v-html="profHyptTitl"></p>            
              <div class="profile_btn_wrap " v-if="!isGA">
                <mo-button class="" @click="fn_MoveToMWS">미리보기</mo-button>
                <mo-button class="" @click="fn_SaveQrCd">QR출력</mo-button>
                <mo-button class="" @click="fn_ChkBeforeMMS">문자발송</mo-button>
              </div>
              </div>
            </ur-box-container>
          </div>
        </ur-box-container>        
      </ur-box-container>
    </ur-box-container>
  </ur-page-container>
</template>
<script>
  /***********************************************************************************
   * 공통 라이브러리 INCLUDE 영역	                                                   *
   ***********************************************************************************/
  import MSPBottomAlert from '@/ui/_cmm/components/MSPBottomAlert'
  import MspBottomSelect from '@/ui/_cmm/components/MspBottomSelect.vue'
  import MSPBC102P from '@/ui/bc/MSPBC102P' // 개인정보 수정
  import MSPBC103P from '@/ui/bc/MSPBC103P' // 프로필 사진 설정
  import MSPBC104P from '@/ui/bc/MSPBC104P' // QR 저장
  import MSPSA203P from '@/ui/sa/MSPSA203P' // 문자 수신 고객 선택 목록
  import MSPCM128P from '@/ui/cm/MSPCM128P' // 문자수신 고객목록
  import MSPBC109P from '@/ui/bc/MSPBC109P' // 개인정보 수정
  import MSPCM152P from '@/ui/cm/MSPCM152P'
  import moment from 'moment'
  import localImg from '@/ui/cm/test'
  import bcConstants from '@/config/constants/bcConstants.js'
  export default {
    /***********************************************************************************
     * Vue 파일 속성 정의 영역	                                                       *
     ***********************************************************************************/
    name: "MSPBC100M",
    screenId: "MSPBC100M",
    components: {
      MSPBC102P,
      MSPBC103P,
      MSPBC104P,
      MSPSA203P,
      MSPCM128P,
      MSPBC109P,
      MSPCM152P,
      MspBottomSelect
    },
    /***********************************************************************************
     * Life Cycle 함수 정의 영역	                                                     *
     ***********************************************************************************/
    created() {
      // this.fn_LoadingUrl() // 모청 URL (암호화 사번)
      let srvcMngScCd = this.getStore('userInfo').getters.getUserInfo.srvcMngScCd
      // if ('GA'.indexOf(srvcMngScCd) > -1) {
      //   this.isGA = true
      // }
    },
    mounted() {
      this.$bizUtil.insSrnLog('MSPBC100M')

      let paramType = this.$route.params.type
      this.fn_LoadingData(paramType) // 필요 정보 조회
      this.fn_selProfHyptInfo() // 한 줄 소개, 프로필 사진 정보 조회

      
      if (!this.$bizUtil.isEmpty(this.$bizUtil.digitProfImg)) {
        console.log('##### MSPBC100M DIGIT PROFILE 있음 #####')
        this.fn_GetProfImg()
      }
      if (process.env.NODE_ENV === 'local') {
        // this.profileImg = `data:image/jpeg;base64, ${localImg.lv_test}`
      }
    },
    /***********************************************************************************
     * 화면변수 선언 영역	                                                             *
     ***********************************************************************************/
    data() {
      return {
        encryptUserEno : '',
        carTestData: [],
        base64Img:'',
        profImgBaseType: '',
        lv_Url: '',
        isGA: false,
        celno: '',
        profHyptTitl: bcConstants.BC_HYPT_DEF_TITL,
        profileImg: require('@/assets/img/profile/ico_life.png'),
        baseImg02: require('@/assets/img/profile/ico_life.png'),
        baseImg03: require('@/assets/img/profile/ico_pro.png'),
        baseImg04: require('@/assets/img/profile/ico_character.png'),
        PROFILE_CNSLT_IMG_DIR: 'D:\\', // 서버 다운로드 Property 정보
        lv_ImgSelectedYn: 'N', // 이미지 저장이 되었는지 여부
        userEno: this.getStore('userInfo').getters.getUserInfo.userId,
        userNm: this.getStore('userInfo').getters.getUserInfo.userNm,
        userDeptNm: this.getStore('userInfo').getters.getUserInfo.userDeptNm,
        userGrd: this.getStore('userInfo').getters.getUserInfo.userCopNm === ' ' || this.getStore('userInfo').getters.getUserInfo.userCopNm === null ? '프로' : this.getStore('userInfo').getters.getUserInfo.userCopNm,
        lv_UserVo: {userEno: '' } // 사용자정보
      };
    },
    /***********************************************************************************
     * 사용자 함수 정의 영역                                                           *
     ***********************************************************************************/
    methods: {
      /******************************************************************************
      * Function명 : fn_selProfHyptInfo
      * 설명       : 코드별 설정 정보 조회(한줄 소개, 저장 프로필 정보)
      ******************************************************************************/
      fn_selProfHyptInfo () {
        let lv_Vm = this
        let pParams = {
          profHyptInfoList:[{profHyptCd:'C00'}]
        }
        let trnstId = 'txTSSBC10S7'
        const auth = 'S'
        this.post(lv_Vm, pParams, trnstId, auth)
          .then(function (response) {
            if ( response && response.body ) {
              let res = response.body
              let  profHyptInfoList = res.profHyptInfoList
              if (profHyptInfoList[0] !== null) {
                let hyptCdYn = profHyptInfoList[0].profHyptCdUseYn
                let profHyptImgNm = profHyptInfoList[0].grpList[0].datalist[0].profHyptImgNm
                if (hyptCdYn === 'Y') {
                  let title = profHyptInfoList[0].grpList[0].datalist[0].profHyptTitl
                  lv_Vm.profHyptTitl = title.replaceAll( /(\n|\r\n)/g, '<br>')                
                }
                if (!lv_Vm.$bizUtil.isEmpty(profHyptImgNm.trim())) {
                  lv_Vm.profImgBaseType = profHyptImgNm.slice(profHyptImgNm.length - 2)
                } else {
                  lv_Vm.profImgBaseType = ''
                }
              }
            } else {
              lv_Vm.getStore('confirm').dispatch('ADD', response.msgComm.msgDesc)
            }
          })
          .catch(function (error) {
            window.vue.error(error)
          })
      },
      /******************************************************************************
      * Function명 : fn_LoadingData
      * 설명       : 고객 정보 조회 함수
      * 인자       : lv_UserInfo 사용자 정보 데이터 (Object)
      ******************************************************************************/
      fn_LoadingData (type) {
        let lv_Vm = this
        let pParams = {}
        const trnstId = 'txTSSBC10S1'
        const auth = 'S'
        this.post(lv_Vm, pParams, trnstId, auth)
          .then(function (response) {
            if ( response && response.body ) {
              lv_Vm.lv_UserVo = response.body
              if (!lv_Vm.$bizUtil.isEmpty(lv_Vm.lv_UserVo.celno.trim())) {
                lv_Vm.celno = lv_Vm.$bizUtil.telNoWithHyphen(lv_Vm.lv_UserVo.celno)
              }
              if (type === 'ban') {
                lv_Vm.fn_MoveMSPBC102P()
              }
            } else {
              lv_Vm.getStore('confirm').dispatch('ADD', response.msgComm.msgDesc)
            }
          })
          .catch(function (error) {
            window.vue.error(error)
          })
      },
      /******************************************************************************
      * Function명 : fn_LoadingUrl
      * 설명       : 모바일 청약을 위한 URL(암호화 사번)
      ******************************************************************************/
      fn_LoadingUrl () {
        let lv_Vm = this
        let pParams = {}
        const trnstId = 'txTSSBC10S2'
        const auth = 'S'
        this.post(lv_Vm, pParams, trnstId, auth)
          .then(function (response) {
            if ( response && response.body ) {
              lv_Vm.encryptUserEno = response.body.encryptUserEno
              lv_Vm.lv_UserVo.encryptUserEno = lv_Vm.encryptUserEno
              let url = ''
              let locHost = location.host
              if (process.env.NODE_ENV === 'local') {
                url = 'http://localhost:8081/#/mobile?mdigitkey=' + lv_Vm.encryptUserEno
              } else if (process.env.NODE_ENV === 'development') {
                if (locHost.indexOf('samsunglife.kr:8960') > -1) {
                  url = 'http://wb1.t.tss.samsunglife.kr:8850/#/mobile?mdigitkey=' + lv_Vm.encryptUserEno // 개발
                }else{
                  url = 'http://wb1.t.tss.samsunglife.kr:8950/#/mobile?mdigitkey=' + lv_Vm.encryptUserEno // ci 개발
                }
              } else if (process.env.NODE_ENV === 'stage' || process.env.NODE_ENV === 'stage2') {
                if (locHost.indexOf('imss') > -1) {
                  url = 'https://imws.samsunglife.com:8440/#/mobile?mdigitkey=' + lv_Vm.encryptUserEno // 검증
                }else{
                  url = 'https://vmws.samsunglife.com:8440/#/mobile?mdigitkey=' + lv_Vm.encryptUserEno // ci 검증
                }
              } else {
                url = 'https://mws.samsunglife.com:8440/#/mobile?mdigitkey=' + lv_Vm.encryptUserEno // 운영
              }
              lv_Vm.lv_Url = url
            }
          })
          .catch(function (error) {
            window.vue.error(error)
          })
      },
      /******************************************************************************
      * Function명 : fn_GetEmailAddr
      * 설명       : 메일 주소 구성
      ******************************************************************************/
      fn_GetEmailAddr ( pObj ) {
        let lv_mail = ''
        if ( pObj.emailAddr && !this.$bizUtil.isEmpty(pObj.emailAddr.trim())) {
          lv_mail = pObj.emailAddr + '@' + this.fn_GetEmailDomain()
        } else {
          lv_mail = ''
        }
        return lv_mail
      },
      /******************************************************************************
      * Function명 : fn_GetEmailDomain
      * 설명       : 이메일도메인 변경
      ******************************************************************************/
      fn_GetEmailDomain () {
        if ( this.getStore('userInfo').getters.getUserInfo.isFc === 'Y' ) {
          return 'samsunglife.com'
        } else {
          return 'samsung.com'
        }
      },
      /******************************************************************************
      * Function명 : fn_ChkBeforeMMS
      * 설명       : 문자 보내기 전 조건 체크
      ******************************************************************************/
      fn_ChkBeforeMMS () {
        let lv_Vm = this

        // 금일 21시 ~ 익일 08시 사이에는 문자 전송 불가능
        lv_Vm.$commonUtil.getServerTime().then(function (response) {
          let todayTime = Number(moment(response).format('YYYYMMDDHHmmss')) // 금일시간
          let todayMorn = Number(moment(response).format('YYYYMMDD') + '080000') // 금일 오전 체크
          let todayEven = Number(moment(response).format('YYYYMMDD') + '210000') // 금일 오후 체크

          if (todayTime < todayMorn || todayTime > todayEven) {
            lv_Vm.getStore('confirm').dispatch('ADD', '오후 9시 ~ 오전 8시에는 문자 전송이 불가합니다. 오전 8시 이후 시도해주세요.')
          } else {
            lv_Vm.fn_OpenPopCustList() // 고객선택 수신 팝업오픈
          }
        })
      },
      /*********************************************************
       * Function명: fn_OpenPopCustList
       * 설명: 고객선택 수신 팝업오픈
       * Params: N/A
       * Return: N/A
       *********************************************************/
      fn_OpenPopCustList() {
        let lv_vm = this
        this.popData = this.$bottomModal.open(MSPCM152P,{
          properties: {
            pChnlCustIds: [
              {targetType: 's',
                parentsViewId: 'MSPBC100M',
                custList: []
              }
            ]
          },
          listeners: {
            onPopupClose: (pData) => {
              // 모달 닫기
              lv_vm.$bottomModal.close(lv_vm.popData)
            },
            onNextService: (pData) => {
              lv_vm.custListObj = [{targetType: 's', parentsViewId: 'MSPBC100M', custList: pData.selectList}] 
              lv_vm.fn_OpenMSPCM128P()
            }
          }
        })
      },
      /*********************************************************
      * Function명: fn_OpenMSPCM128P
      * 설명: 수신고객목록 팝업오픈
      *********************************************************/
      fn_OpenMSPCM128P: _.debounce(function () {
        let lv_vm = this
        lv_vm.popData1 = lv_vm.$bottomModal.open(MSPCM128P,{
          properties: {
            pChnlCustIds: lv_vm.custListObj // param셋팅
          },
          listeners: {
            // 단순 팝업 종료
           onPopupCancel: () => {
             lv_vm.$bottomModal.close(lv_vm.popData1)
           },    
            // 팝업 닫히면서 실행되는 함수
            onPopupClose: (pData) => {
              // console.log('#### MSPBC100M Selected Cust LIST ####', pData)
              if (pData.str === 'confirm') {
                let targetList = pData.rtnData.smsTargetCustList
                lv_vm.fn_sendMMS(targetList)
                lv_vm.$bottomModal.close(lv_vm.popData)
                lv_vm.$bottomModal.close(lv_vm.popData1)
              } else {
                lv_vm.$bottomModal.close(lv_vm.popData1)
              }
            }
          }
        })
      }, 300), 
      /*********************************************************
      * Function명: fn_sendMMS
      * 설명: MMS 문자 전송
      *********************************************************/
      fn_sendMMS (targetList) {
        if (targetList.length > 0) {
          let pPhoneNum = ''
          for (let i = 0; i < targetList.length; i++) {
            if (pPhoneNum === '') {
              pPhoneNum += targetList[i].celno
            } else {
              pPhoneNum += ',' + targetList[i].celno
            }
          }
          let url = this.fn_setUrl()
          let message = '[광고] 삼성생명\n' +
                        '고객님 안녕하세요.\n' +
                        '삼성생명 '+ this.userNm +' 컨설턴트 입니다.\n\n' +                                        
                        '↓바로가기 클릭\n' +                                        
                        '디지털 프로필\n' +                                        
                        url + '\n\n' +                                        
                        '삼성생명 '+ this.userNm +'컨설턴트\n' +
                        '무료 수신거부 080-856-4713'
          let imgSrc = ''
          if (!this.$bizUtil.isEmpty(this.profImgBaseType)) { // 기본이미지
            imgSrc = this[`baseImg${this.profImgBaseType}`]
          } else {
            imgSrc = this.$refs.profileImgRef.src
          }
          // imgSrc = this.baseImg02 // test
          fetch(imgSrc)
            .then((res) => res.blob())
            .then((blob) => {
              const reader = new FileReader()
              reader.onloadend = () => {
                let lv_imgSrc = reader.result.split(',')[1].replace(' ', '')
                if (process.env.NODE_ENV === 'local') {
                  console.log('#### MMS SEND PONE IMG !!!!', lv_imgSrc)
                } else {
                  window.fdpbridge.exec('MMSPlugin', {number: pPhoneNum, text: message, imgSrc: lv_imgSrc}, (result) => {
                    console.log('#### MMSPlugin SUCCESS CALL!!!!')
                    this.fn_SendSMSRslt(targetList) // 문자 전송내역 저장
                  }, (result) => {
                    console.log('#### MMSPlugin FAIL !!!!!!!!!!!', result)
                  })
                }
              }
              reader.readAsDataURL(blob)
            })
        }
      },
      /******************************************************************************
       * Function명: fn_SendSMSRslt
       * 설명: 문자 전송 내역을 서버로 보낸다.
       ******************************************************************************/
      fn_SendSMSRslt (targetList) {
        var lv_Vm = this
        let pParams = { bCFcTouchSVO: [], sACntntBasInfoSVO: [] }

        // 고객정보
        for (let index = 0; index < targetList.length; index++) {
          pParams.bCFcTouchSVO.push({ custCntcId: 'HC09', chnlCustId: targetList[index].chnlCustId, custNm: targetList[index].custNm, phclTm: '' })
        }

        this.post(lv_Vm, pParams, 'txTSSBC22I2', 'I', {})
          .then(function (response) {
            if ( response && response.body ) {
              // lv_Vm.getStore('toast').dispatch('ADD', '문자 전송이 완료되었습니다.')
            }
          })
          .catch(function (error) {
            window.vue.error(error)
          })
      },
      /******************************************************************************
      * Function명 : fn_UpdPhoto
      * 설명       : 사진수정 팝업오픈
      ******************************************************************************/
      fn_UpdPhoto () {
        this.popup103 = this.$bottomModal.open(MSPBC103P, {
          properties: {
            params: {profImgBaseType: this.profImgBaseType}
          },
          listeners: {
            confirmPopup: (pData) => {
              console.log('##### Data from MSPBC103P=========>', pData)
              if (!this.$bizUtil.isEmpty(pData.type)) {
                // [디지털 프로필] 이미지 조회
                this.$bizUtil.selDigitProfImg().then(() => {
                  if (!this.$bizUtil.isEmpty(this.$bizUtil.digitProfImg)) {
                    console.log('#### MSPBC100M 이미지 재조회 완료 #### ')
                    this.fn_GetProfImg()
                  }
                  if (process.env.NODE_ENV === 'local') {
                    this.profileImg = '/profimgdown.do?userEno=' + this.userEno
                  }
                  this.fn_selProfHyptInfo() // 한줄소개, 기본이미지 저장 정보 조회
                })
              }
              this.$bottomModal.close(this.popup103)
            },
            cancelPopup: () => {
              this.$bottomModal.close(this.popup103)
            }
          }
        },
        {
          properties: { closeBtn: true },
          listeners: {
            "before-close": (fn) => {
              this.fn_GetProfImg()
              fn(this.popup103)
            }
          }
        })
      },
      /******************************************************************************
      * Function명 : fn_SaveQrCd
      * 설명       : QR 코드 저장
      ******************************************************************************/
      fn_SaveQrCd () {
        this.popup104 = this.$bottomModal.open(MSPBC104P, {
          properties: {
            title: 'QR'
            ,params:this.lv_UserVo
          },
          listeners: {
            confirmPopup: () => {
              this.$bottomModal.close(this.popup104)
            },
            cancelPopup: () => {
              this.$bottomModal.close(this.popup104)
            }
          }
        },
        {
          properties: { 
            closeBtn: true,
            noHeader: true
          }
        })
      },
      /******************************************************************************
      * Function명 : fn_MoveMSPBC102P
      * 설명       : 디지털프로필 가이드
      ******************************************************************************/
      fn_MoveMSPBC102P () {
        this.popup102 = this.$bottomModal.open(MSPBC102P, {
          properties: {
            pPage: 'MSPBC102P',
            lv_UserVo: this.lv_UserVo,
            pSrnId: 'MSPBC100M'
          },
          listeners: {
            confirmPopup: () => {
              this.$bottomModal.close(this.popup102)
            }
          }
        })
      },
      fn_setUrl () {
        let url = ''
        let locHost = location.host
        if (process.env.NODE_ENV === 'local') {
          url = 'http://localhost:8081/#/mobile?mdigitkey=' + this.lv_UserVo.userEno
        } else if (process.env.NODE_ENV === 'development') {
          if (locHost.indexOf('samsunglife.kr:8960') > -1) {
            url = 'http://wb1.t.tss.samsunglife.kr:8950/#/mobile?mdigitkey=' + this.lv_UserVo.userEno // 개발
          }else{
            url = 'http://wb1.t.tss.samsunglife.kr:8850/#/mobile?mdigitkey=' + this.lv_UserVo.userEno // ci 개발
          }
        } else if (process.env.NODE_ENV === 'stage' || process.env.NODE_ENV === 'stage2') {
          if (locHost.indexOf('imss') > -1) {
            url = 'https://imws.samsunglife.com:8440/#/mobile?mdigitkey=' + this.lv_UserVo.userEno // pjt 검증
          }else{
            url = 'https://vmws.samsunglife.com:8440/#/mobile?mdigitkey=' + this.lv_UserVo.userEno // ci 검증
          }
        } else {
          url = 'https://mws.samsunglife.com:8440/#/mobile?mdigitkey=' + this.lv_UserVo.userEno // 운영
        }
        return url
      },
      fn_MoveToMWS () {
        let url = this.fn_setUrl()
        console.log('mws url', url)
        // window.open(url, '_blank')
        if (this.$commonUtil.isMobile()) { // 모바일에서 작동
          window.fdpbridge.exec('intentBrowserPlugin', {uri: url}, () => {}, () => {})
        } else { // PC에서 작동
          window.open(url, '_blank')
        }
      },
      /******************************************************************************
      * Function명 : fn_MoveToMSPBC101M
      * 설명       : 디지털프로필 관리 페이지 이동 (MSPBC101M)
      ******************************************************************************/
      fn_MoveToMSPBC101M () {
        this.lv_UserVo.email = this.fn_GetEmailAddr(this.lv_UserVo)        
        this.$router.push( {name: 'MSPBC101M', params: this.lv_UserVo} )
      },
      fn_updUserInfo () {
        this.popup109 = this.$bottomModal.open(MSPBC109P, {
          properties: {
            pPage: 'MSPBC109P',
            lv_UserVo: this.lv_UserVo,
            pSrnId: 'MSPBC100M'
          },
          listeners: {
            confirmPopup: () => {
              this.fn_LoadingData()
              this.$bottomModal.close(this.popup109)
            },
            cancelPopup: () => {
              this.$bottomModal.close(this.popup109)
            }
          }
        },
        {
          properties: {
            closeBtn: true
          }
        })
      },
      fn_GetProfImg () {
        let imgUrl = '/profimgdown.do?userEno='
        if (process.env.NODE_ENV === 'stage' || process.env.NODE_ENV === 'stage2') {
          let locHost = location.host
          if (locHost.indexOf('imss') > -1) {
            imgUrl = 'https://itss.samsunglife.com:8410' + imgUrl // 임시로 우회처리
          }
        }

        let timeStamp = moment(new Date().toISOString()).format('YYYYMMDDHHmmss').toString().split('').reverse().join('')
        this.profileImg = imgUrl + this.userEno + '&sno=' + timeStamp// 미리보기용 이미지
      }
    }
  };
</script>