/*
* 업무구분: 디지털프로필
* 화면 명: MSPBC109P
* 화면 설명: 개인정보 수정 요청
*/
<template>
   <ur-page-container class="msp" title="개인정보 수정 요청" :show-title="false" type="popup">
    <ur-box-container direction="column" alignV="start">
      <ur-box-container alignV="start" componentid="ur_box_container_001" direction="column">
        <div class="ns-bottom-sheet full">
          <div class="untact-pop-box">
            <span class="fs17rem">휴대폰 번호 수정 요청 후 담당자 확정 시 최종 반영됩니다.</span>
          </div>
          <div class="untact-pop-box mt30 mb20">            
            <span class="fs14rem crTy-bk7">휴대폰 번호</span>
            <ur-box-container alignV="start" componentid="ur_box_container_001" direction="column" class="ns-check">
              <mo-decimal-field numeric v-model="celno" :rules="validateRuleCelno" mask="###-####-####"  underline placeholder="000-0000-0000" class="phone-number1 form-input-name full"/>
            </ur-box-container>
          </div>
          <!-- <div class="untact-pop-box mt30">
            <span class="fs14rem crTy-bk7">회사 이메일</span>
            <ur-box-container alignV="start" componentid="ur_box_container_001" direction="row" class="ns-check">
              <mo-text-field v-model="emailAddr" :rules="validateRuleEmail" placeholder="이메일" underline class="nw-input full flex-1"/>
              <span class="mt10 mb30 ml4 flex-0">@{{mailDomain}}</span>
            </ur-box-container>
          </div> -->
          <ur-box-container align-v="start" componentid="ur_box_container_011" direction="row" align-h="end" ref="_modal__action" class="ns-btn-relative-bs">
            <div class="relative-div">
              <mo-button componentid="mo_button_005" color="primary" shape="solid" size="medium" @click="fn_CancelPop" class="ns-btn-round white">취소</mo-button>
              <mo-button componentid="mo_button_006" color="normal" shape="border" size="medium" @click="fn_ConfirmPop" class="ns-btn-round blue" :disabled="celno.trim().length < 7">요청</mo-button>
            </div>
          </ur-box-container>
        </div>
      </ur-box-container>
    </ur-box-container>
  </ur-page-container>
</template>
<script>
  /***********************************************************************************
   * 공통 라이브러리 INCLUDE 영역	                                                   *
   ***********************************************************************************/
  export default {
    /***********************************************************************************
     * Vue 파일 속성 정의 영역	                                                       *
     ***********************************************************************************/
    name: "MSPBC109P",
    screenId: "MSPBC109P",
    components: {
    },
    props: {
      lv_UserVo: {}
    },
    /***********************************************************************************
     * 화면변수 선언 영역	                                                             *
     ***********************************************************************************/
    data() {
      return {
        value1: true,
        value2: true,
        celno:'',
        emailAddr: '',
        mailDomain: '',
        validateRuleEmail: [
          v => !!v || '필수입력항목입니다',
          v => v.length > 2 || '메일주소를 입력하세요.'
        ],
        validateRuleCelno: [
          v => !!v || '필수입력항목입니다',
          v => v.length > 7 || '휴대폰번호를 입력하세요.'
        ]
      };
    },
    /***********************************************************************************
     * Computed 함수 정의 영역                                                         *
     ***********************************************************************************/
    computed: {
    },
    created () {
    },
    mounted () {
      this.$bizUtil.insSrnLog('MSPBC109P')
      if (!this.$bizUtil.isEmpty(this.lv_UserVo.celno)) {
        this.celno = this.lv_UserVo.celno
      }
      if (!this.$bizUtil.isEmpty(this.lv_UserVo.emailAddr)) {
        this.emailAddr = this.lv_UserVo.emailAddr
      } else {
        this.emailAddr = ' '
      }
      this.fn_Init()
    },
    /***********************************************************************************
     * 사용자 함수 정의 영역                                                           *
     ***********************************************************************************/
    methods: {
      /*********************************************************
       * Function명: fn_Init
       * 설명: 로딩시 정보항목 초기화 및 설정
       * Params: N/A
       * Return: N/A
       *********************************************************/
      fn_Init () {
        // 메일 도메인
        if ( this.getStore('userInfo').getters.getUserInfo.isFc === 'Y' ) {
          this.mailDomain = 'samsunglife.com'
        } else {
          this.mailDomain = 'samsung.com'
        }
      },
      fn_ConfirmPop () {
        let lv_Vm = this
        let lv_celno = this.celno.replace('-', '').replace('-', '')
        if (lv_celno === this.lv_UserVo.celno) {
          lv_Vm.getStore('toast').dispatch('ADD', '변경 정보가 없습니다.')
          return
        }
        let pParams = {
          userEno: this.lv_UserVo.userEno,
          celno: lv_celno,
          emailAddr: this.emailAddr
        }
        const trnstId = 'txTSSBC20U1'
        const auth = 'U'
        this.post(lv_Vm, pParams, trnstId, auth)
          .then(function (response) {
            if (response.msgComm.msgCd === 'EFWS027') {
              lv_Vm.getStore('toast').dispatch('ADD', '요청되었습니다.')
              lv_Vm.$emit('confirmPopup')
            } else {
              lv_Vm.getStore('toast').dispatch('ADD', response.msgComm.msgDesc)
            }
          })
          .catch(function (error) {
            window.vue.error(error)
          })
      },
      fn_CancelPop () {
        this.$emit('cancelPopup')
      }
    }
  }
</script>