/*
* 업무구분: 디지털프로필
* 화면 명: MSPBC102P
* 화면 설명: 디지털프로필 가이드
*/
<template>
  <ur-page-container  type="subpage" :show-title="true" title="디지털 프로필 가이드" :topButton="false">
    <ur-box-container class="msp msp-bc-wrap--bgGray" direction="column" alignV="start">
    <!-- Content영역 -->
    <ur-box-container direction="column" alignV="start" class="msp-bc-wrap">
      <ur-box-container alignV="start" componentid="" direction="column" class="pl24 pr24 mt30">
        <ur-box-container alignV="start" componentid="" direction="column" class="home_guide--box">
          <span class="fs14rem fwb crTy-blue4">고객과의 첫 만남.</span>
          <span class="fs22rem"><em class="fwb crTy-blue3">{{lv_UserVo.userNm}}</em> 컨설턴트님의</span>
          <span class="fs22rem"><em class="fwb">디지털 프로필</em>로 <em class="fwb">시작</em>하세요.</span>
        </ur-box-container>        
      </ur-box-container>
      <ur-box-container alignV="start" componentid="" direction="column" class="pl24 pr24 mt30 mb150">
        <ur-box-container alignV="start" componentid="" direction="column" class="profile_guide--box">
          
          <!-- 슬라이딩 배너 영역 -->
          <div class="ns-swiper-main profile_guide"> <!-- ns-swiper-main -->
            <mo-carousel number-of-page="5" height="auto" :swiper-option="swiperOption" :prevButton="false" :nextButton="false" class="guide_roll">
              <template #1>
                <div class="guide_group">
                  <div class="txt-block">
                    <span class="fs19rem fwb">디지털프로필이란?</span>
                    <span class="fs16rem mt5">나의 연락처와 전문분야, 커리어를 소개할 수 있는 나만의 웹페이지입니다.</span>
                  </div>
                  <div class="guide-img">
                    <img src="@/assets/img/profile/img_guide01.png" />
                  </div>
                </div>
              </template>
              <template #2>
                <div class="guide_group">
                  <div class="txt-block">
                    <span class="fs19rem fwb">디지털 프로필, 이렇게 만들어보세요.</span>
                    <span class="fs16rem mt5">먼저, 디지털 프로필 관리에서<br> 나의 프로필 사진과 소개문구를 설정하세요.</span>
                  </div>
                  <div class="guide-img">
                    <img src="@/assets/img/profile/img_guide02.png" />
                  </div>
                </div>
              </template>
              <template #3>
                <div class="guide_group">
                  <div class="txt-block">
                    <span class="fs19rem fwb">디지털 프로필, 이렇게 사용하세요.</span>
                    <span class="fs16rem mt5">설정 후, 고객들에게 디지털 프로필 URL주소를 문자로 발송해 보세요.</span>
                  </div>
                  <div class="guide-img">
                      <img src="@/assets/img/profile/img_guide03.png" />
                  </div>
                </div>
              </template>
              <template #4>
                <div class="guide_group">
                  <div class="txt-block">
                    <span class="fs19rem fwb">디지털 프로필, 이렇게 사용하세요.</span>
                    <span class="fs16rem mt5">나의 연락처, 소개, 전문분야가 들어간 페이지를 고객이 확인하고 다른 사람에게 공유할 수 있어요.</span>
                  </div>
                  <div class="guide-img">
                      <img src="@/assets/img/profile/img_guide04.png" />
                  </div>
                </div>
              </template>
              <template #5>
                <div class="guide_group">
                  <div class="txt-block">
                    <span class="fs19rem fwb">디지털 프로필, 이렇게 사용하세요.</span>
                    <span class="fs16rem mt5">내 프로필로 바로 연결되는 QR코드를 출력해서 택배/DM 활동에 활용하세요.</span>
                  </div>
                  <div class="guide-img">
                      <img src="@/assets/img/profile/img_guide05.png" />
                  </div>
                </div>
              </template>
            </mo-carousel>
          </div>
          <!-- 슬라이딩 배너 영역 //-->
        </ur-box-container> 
      </ur-box-container>

    </ur-box-container>
    <ur-box-container alignV="start" componentid="ur_box_container_002" direction="column" class="ns-btn-relative">
      <div class="relative-div">
        <mo-button componentid="mo_button_002" color="primary" shape="border" size="medium" class="ns-btn-round blue" @click="fn_MoveToMSPBC101M">설정하기</mo-button>
      </div>
    </ur-box-container>

    </ur-box-container>
  </ur-page-container>
</template>
<script>
  /***********************************************************************************
   * 공통 라이브러리 INCLUDE 영역	                                                   *
   ***********************************************************************************/
  export default {
    /***********************************************************************************
     * Vue 파일 속성 정의 영역	                                                       *
     ***********************************************************************************/
    name: "MSPBC102P",
    screenId: "MSPBC102P",
    components: {
    },
    props: {
      lv_UserVo: {}
    },
    modalProps: {
      mode: 'right' // 우->좌 open popup (defalut: 하->상)
    },
    /***********************************************************************************
     * 화면변수 선언 영역	                                                             *
     ***********************************************************************************/
    data() {
      return {
        swiperOption: {
          centerSlides: true,
          slidesPerView: 1,
          spaceBetween: 20,
          autoplay: {
            delay: 5000,
            disableOnInteraction: false, 
          },
        }
      };
    },
    /***********************************************************************************
     * Computed 함수 정의 영역                                                         *
     ***********************************************************************************/
    computed: {
      
    },
    created () {
    },
    mounted () {
      this.$bizUtil.insSrnLog('MSPBC102P')
    },
    /***********************************************************************************
     * 사용자 함수 정의 영역                                                           *
     ***********************************************************************************/
    methods: {
      /******************************************************************************
      * Function명 : fn_MoveToMSPBC101M
      * 설명       : 디지털프로필 관리 페이지 이동 (MSPBC101M)
      ******************************************************************************/
      fn_MoveToMSPBC101M () {
        let mailDomail = ''
        let email = ''
        if ( this.getStore('userInfo').getters.getUserInfo.isFc === 'Y' ) {
          mailDomail = 'samsunglife.com'
        } else {
          mailDomail = 'samsung.com'
        }
        email = this.lv_UserVo.emailAddr + '@' + mailDomail
        this.lv_UserVo.email = email
        this.$router.push( {name: 'MSPBC101M', params: this.lv_UserVo} )
        this.$emit('confirmPopup')
      }
    }
  }
</script>