/*
* 업무구분: 디지털프로필 메인 > QR 저장
* 화면 명: MSPBC104P
* 화면 설명: QR 코드
*/
<template>
  <ur-page-container class="msp" type="popup" :show-title="false" title="QR출력">
      <div class="ns-btn-close" @click="fn_CancelPop" name="닫기"></div>
    <ur-box-container alignV="start" componentid="ur_box_container_002" direction="column" class="ns-bottom-sheet">
      <div class="pt20 pr24 pb20 pl24 w100 fexTy3">
        <div>
          <span class="fs18rem fwb">QR출력</span>
          <span class="ns-tool-tip ml10">
            <mo-icon id="home-mobps-intrs" icon="msp-question" class="mt5 home_qr_icon">msp-question</mo-icon>
            <mo-tooltip target-id="home-mobps-intrs" class="tool-tip-box">
              <div>
                <div class="txt-block">
                  <span style="font-size: 1rem; font-weight: bold;">디지털 프로필, 이렇게 사용하세요.</span>
                </div>
                <div class="txt-block" style="margin-top: 10px;">
                  <span>내 프로필로 바로 연결되는 QR코드를 출력해서 택배/DM 활동에 활용하세요.</span>
                </div>
                <img src="@/assets/img/profile/img_guide05.png" style="max-width: 280px; margin-top: 30px"/>
              </div>
            </mo-tooltip>
          </span>
        </div>
        <mo-icon icon="close" class="" @click="fn_CancelPop">close</mo-icon>
      </div>
      <div class="full home_profile--bs">
    <!--  바텀 시트 컨텐츠 추가 영역 시작 -->
        <div class="content-area">
          <div class="home_profile--qr">
            <div class="profile_qr--box txt-center">
                <qrcode-vue :value="Iv_qrUrl" :size="100" level="H" id="qrCanvas" ref="refqr" class="qr_box">
                </qrcode-vue>
              <p class="fs19rem mt20 mb10 fwb">{{this.Iv_profData.userNm}}</p>
              <span class="fs14rem">삼성생명 {{this.Iv_profData.deptNm}}</span>
            </div>
          </div>
          <div class="home_profile_qr--count">
            <!-- <div class="fexTy1"> -->
            <div class="fexTy1 mr32">
              <span class="fs14rem mr8 crTy-bk7">매수</span>
              <mo-stepper v-model="Iv_qrCnt" :min="1" :max="20" :value="1" />
            </div>
            <p>{{this.Iv_qr_cnt_msg}}</p>
          </div>


          <!-- <div class="home_profile_qr--count">
            <mo-stepper v-model="Iv_qrCnt" :min="1" :max="20" :value="1" />
            <p>{{this.Iv_qr_cnt_msg}}</p>
          </div> -->
        </div>
    <!-- 바텀 시트 컨텐츠 추가 영역 끝-->
      </div>
    </ur-box-container> 

    <ur-box-container alignV="start" componentid="ur_box_container_002" direction="column" class="ns-btn-relative-bs">
      <div class="relative-div">
        <mo-button componentid="mo_button_002" color="primary" shape="border" size="medium" class="ns-btn-round white" @click="fn_CancelPop">취소</mo-button>
        <mo-button componentid="mo_button_002" color="primary" shape="border" size="medium" class="ns-btn-round blue" @click="fn_OzPrintService">출력</mo-button>
      </div>
    </ur-box-container>

  </ur-page-container>
</template>
<script>
// import Screen from '~systems/mixin/screen'
import QrcodeVue from 'qrcode.vue'
  /***********************************************************************************
   * 공통 라이브러리 INCLUDE 영역	                                                   *
   ***********************************************************************************/
  export default {
    /***********************************************************************************
     * Vue 파일 속성 정의 영역	                                                       *
     ***********************************************************************************/
    name: "MSPBC104P",
    screenId: "MSPBC104P",
    components: {
      QrcodeVue
    },
    // mixins: [Screen],
    props: {
      title: {
        type: String,
        default: 'QR' 
      },
      params: {
        type: Object,
        default: {}
      }
    },
    /***********************************************************************************
     * Life Cycle 함수 정의 영역	                                                     *
     ***********************************************************************************/
    created() {
      this.actionSlot = {
        actionComponent: {
          template:'<mo-button class="btn_mo-icon icon-wrapper" style="position: absolute; left: 140px; top: 36px;" id="location0"><mo-icon id="location0" icon="msp-question">msp-question</mo-icon></mo-button>',
          methods: {
          }
        }
      }
    },
    mounted() {
      this.$bizUtil.insSrnLog(this.constructor.extendOptions.name) // 화면 접속기록 남기기

      this.Iv_cnsltInfo = this.params
      this.Iv_profData.userNm = this.getStore('userInfo').getters.getUserInfo.userNm
      this.Iv_profData.deptNm = this.getStore('userInfo').getters.getUserInfo.userDeptNm
      this.Iv_profData.chnlScCd = this.Iv_cnsltInfo.chnlScCd

// console.log('this.Iv_cnsltInfo.srvcMngScCd='+this.getStore('userInfo').getters.getUserInfo.srvcMngScCd)

      this.fn_qrUrl() // 현재 구동된 server환경에 따른 url 매핑 
      this.setQrCanvasClass()
      // let qrBase64 = this.$refs.refqr.$refs['qrcode-vue'].toDataURL('image/png', 1.0)
    },
    /***********************************************************************************
     * 화면변수 선언 영역	                                                             *
     ***********************************************************************************/

    data() {
      return {
        value1: true,
        value2: true,
        Iv_cnsltInfo:{},
        Iv_qrUrl: '', 
        Iv_qr_cnt_msg: '1장에 6개씩 출력됩니다.',
        Iv_profData: {userEno:'', userNm:'', deptNm:'', chnlScCd:'', qrUrl:''}, // ozReport세팅에서도 사용 
        Iv_qrCnt:1,
        Iv_qrImg:''
      };
    },
    /***********************************************************************************
     * Computed 함수 정의 영역                                                         *
     ***********************************************************************************/
    computed: {
    },
    watch: { },
    /***********************************************************************************
     * 사용자 함수 정의 영역                                                           *
     ***********************************************************************************/
    methods: {
      fn_ConfirmPop () {
        this.$emit('confirmPopup')
      },
      fn_CancelPop () {
        this.$emit('cancelPopup')
      },
      setQrCanvasClass() {
        document.getElementsByTagName('canvas')[0].setAttribute("class","qr_img") 
        // let base64img = document.getElementsByTagName('canvas')[0].toDataURL('image/png')
        // this.Iv_qrImg = base64img
      },
      fn_qrUrl()  {
        console.log('##### fn_qrUrl #####'+ process.env.NODE_ENV)
        let server = process.env.NODE_ENV
        let url = 'https://mws.samsunglife.com:8440/#/mobile?mdigitalkey\=' 
        let locHost = location.host
console.log('##### locHost #####'+ locHost)
console.log('##### locHost.indexOf(imss) #####'+ locHost.indexOf('imss'))

        if (server === 'local') {
          url = 'https://imws.samsunglife.com:8440/#/mobile?mdigitkey\=' + this.Iv_cnsltInfo.userEno // PJT 검증 
        } else if (server === 'development') {
          if (locHost.indexOf('samsunglife.kr:8960') > -1) {
            url = 'http://wb1.t.tss.samsunglife.kr:8950/#/mobile?mdigitkey\=' + this.Iv_cnsltInfo.userEno // 개발
          }else{
            url = 'http://wb1.t.tss.samsunglife.kr:8850/#/mobile?mdigitkey\=' + this.Iv_cnsltInfo.userEno // ci 개발
          }
        } else if (server === 'stage' || server === 'stage2') {
          if (locHost.indexOf('imss') > -1) {
            url = 'https://imws.samsunglife.com:8440/#/mobile?mdigitkey\=' + this.Iv_cnsltInfo.userEno // PJT 검증
          }else{
            url = 'https://vmws.samsunglife.com:8440/#/mobile?mdigitkey\=' + this.Iv_cnsltInfo.userEno // ci 검증
          }
        } else {
          url = 'https://mws.samsunglife.com:8440/#/mobile?mdigitkey\=' + this.Iv_cnsltInfo.userEno // qr코드 주소 
        }
        
        this.Iv_qrUrl = url
        this.Iv_profData.qrUrl = this.Iv_qrUrl
      },      
      /******************************************************************************
       * Function명 : fn_OzPrintService
       * [보험거래문서디지털화PJT] OZ 출력 서비스 추가
       * 설명       : OZ 인쇄 서비스 호출
       ******************************************************************************/
      fn_OzPrintService() {  
        let cnsltInfo = this.Iv_cnsltInfo //cnsltInfo 정보는 MSPBC100P에서 넘겨준 정보
        var lv_Vm = this
        let trnstId = 'txTSSSA11P8' // SA에서 기존에 사용하던 로직을 이용해 전문만 '디지털프로필'QR출력 전문으로 생성
        const auth = 'S'                                
        let lv_NewFomName = '디지털프로필'
        let prtCntntSVO = {}
        prtCntntSVO.untySlmtId = '*' // 통합영업자료ID 
        prtCntntSVO.prdtNm = ''
        prtCntntSVO.pblUcst = 0 // 발행단가
        prtCntntSVO.bnwUcst = 0 // 흑백단가
        prtCntntSVO.colorUcst = 0 // 색상단가

        prtCntntSVO.cnsltNo = cnsltInfo.userEno // 컨설턴트번호
        prtCntntSVO.fofNo = cnsltInfo.fofOrgNo // 지점조직번호
        prtCntntSVO.pblSvrSno = '0' // 발행일련번호

        prtCntntSVO.pblSvrIp = ' ' // 발행서버IP -> 출력전문 PO에서 사번기준으로 발행서버조회하므로, 빈값으로 세팅 

        prtCntntSVO.cnsltNm = cnsltInfo.userNm // 컨설턴트명
        prtCntntSVO.chnlScCd = cnsltInfo.chnlScCd // 채널구분코드
        prtCntntSVO.srcFileNm01 = lv_NewFomName // 원천화일명
        prtCntntSVO.bfPrntYn = 'N' // 양면인쇄여부
        prtCntntSVO.pblNos01 = 1 // 발행매수 (무조건 1로 세팅 )
        prtCntntSVO.doctStoreExnex = 'N' // PDF전환유무        
        prtCntntSVO.trnsScCd = '20'

        // console.log('■■■■■ MSPBC104P ■■■■■ 발행서버: ' + prtCntntSVO.pblSvrIp)                
        var iCCntntCustInfoVO01 = []
        var mOICCntntCustInfoVO = {}
        mOICCntntCustInfoVO.tlgmCntnt = decodeURIComponent(lv_Vm.fn_GetOzDDS(prtCntntSVO)) // 전문
        
        iCCntntCustInfoVO01.push(mOICCntntCustInfoVO)
        prtCntntSVO.iCCntntCustInfoVO01 = iCCntntCustInfoVO01

        console.log('■■■■■ MSPBC104P ■■■■■ all 전문: ',prtCntntSVO )
        
        lv_Vm.post(lv_Vm, prtCntntSVO, trnstId, auth)
          .then(function(response) {
            if (lv_Vm.$commonUtil.isSuccess(response)) { // response.msgComm.msgCd 값을 통해 this.$commonUtil 메소드에서 성공 여부를 체크
              // 서비스 후처리
              if (response.body !== null && response.body !== '') { // 서비스 호출 결과(body)가 있을 경우
                // 출력 건수가 선택된 아이템별 선택 매수와 동일할 경우
                lv_Vm.getStore('toast').dispatch('ADD', '인쇄 되었습니다.')
                //lv_Vm.$refs.ozPrint.close()
              } else { // 서비스 호출은 정상이지만 비지니스 에러가 존재할 경우 코드 및 메시지 출력
                lv_Vm.getStore('confirm').dispatch('ADD', '[' + response.msgComm.msgCd + ']\n' + response.msgComm.msgDesc)
              }
            } else {
              // 서비스에서 에러 코드를 던지면, 해당 메세지를 confirm 창을 통해 표시
              lv_Vm.getStore('confirm').dispatch('ADD', response.msgComm.msgDesc)
            }
          })
          .catch(function(error) {
            window.vue.error(error)
            // 시스템적인 오류가 발생하면, 일반적인 에러 메세지를 confirm으로 표시
            lv_Vm.getStore('confirm').dispatch('ADD', '인쇄 실패하였습니다.')
          })
       
      },
      /*******************************************************************************
       * Function명: fn_GetOzDDS
       * [사랑온 미니 PJT] 추가
       * 설명: OZ 전문 생성
       * { 
       *   "FORM_ARY":[{"formId":""}],
       *   "DATA_ARY":[{"SingleValueMap":{}}],
       *   "META_ARY":[{}],
       *   "PBL_DFNT":{}      // PDF 생성 시
       * }
       *******************************************************************************/
      fn_GetOzDDS( prtCntntSVO) {
        var sData = ''
        sData += '{' // 전문 시작
        // FORM_ARY
        sData += '"FORM_ARY":[{"formId":"/IC/digitalprofileqr"}]' // formId  
        // DATA_ARY
        sData += ',"DATA_ARY":[{"SingleValueMap":{'
         // Iv_profData: {userEno:'', userNm:'', deptNm:'', chnlScCd:'',qrUrl:this.Iv_qrUrl}
         // FC 명
          sData += '"fc_nm":"' + this.Iv_profData.userNm + '"'
         // 지점명 
          sData += ',"dept_nm":"' + this.Iv_profData.deptNm + '"' 
         // QR url정보 
         sData += ',"qr_url":"' + this.Iv_profData.qrUrl + '"'
         // 출력 장수 
         sData += ',"page_cnt":' + this.Iv_qrCnt       

        sData += '}}]' // DATA_ARY END
        sData += ',"META_ARY":[{"formname":"digitalprofileqr","title":"디지털프로필","color":"C","duplex":"1","WTMK_NO":""}]'
        // if (prtCntntSVO.doctStoreExnex === 'Y') { // PDF 생성 시
        //   sData += ',"PBL_DFNT":{"fcname":"' + this.Iv_profData.userNm + '","fccode":"' + this.Iv_profData.userEno + '","title": "","merge": "Y","entplid": "","tbtpblno": "","channel": "' + this.Iv_profData.chnlScCd + '","outgoing": ""}}'
        // }
        console.log('## sData: ', sData)
        return sData
      }

    }
  }
</script>